<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Impact Test – Test 3 (a) (vii)"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="MBOM Impact Machine Description"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/MBOMDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/MBOMDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to measure the sensitiveness of the substance to
              drop-mass impact and to determine if the substance is unstable in
              the form it was tested. The Modified Bureau of Mines (MBOM) Impact
              machine is applicable to solid, semisolid, liquid, and powder
              substances.
            </p>
            <TitleComponent
              class="pt-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are seven different impact sensitivity tests in the UN
              Manual of Tests and criteria. See the UN Manual of Tests and
              Criteria for further details.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Sample condition</td>
                    <td>Repeatably obtain results for a given sample</td>
                    <td></td>
                    <td>
                      Substances are tested in the form received with wetted
                      substances tested with the minimum quantity of wetting
                      agent required for transport. Solid samples are cut to a
                      thickness of 0.08 ± 0.01 cm (0.033 ± 0.004 in).
                    </td>
                  </tr>
                  <tr>
                    <td>Drop Mass</td>
                    <td>Source of energy</td>
                    <td></td>
                    <td>Mass standardized to 2.00 kg</td>
                  </tr>
                  <tr>
                    <td>Drop Height</td>
                    <td>Source of energy</td>
                    <td>
                      Drop height selected to yield at least 1 in 6 reactions
                      when testing dry PETN, dry RDX, and NG but not for some
                      other common explosives as reported in the UN Manual of
                      Tests and Criteria for tests with the BOE Impact machine
                    </td>
                    <td>17 cm for solids and semi-solids; 11 cm for liquids</td>
                  </tr>
                  <tr>
                    <td>Drop Mass Guides</td>
                    <td>
                      Minimal sliding friction (no binding) which can be
                      qualified by drop time from a specified height
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Intermediate Mass Assembly</td>
                    <td>
                      Transfer energy from the drop mass into the sample through
                      the impact insert, providing a uniform, repeatable strike
                    </td>
                    <td></td>
                    <td>
                      Standardized to 1.0 kg without the collar, nut, and insert
                      and is 1.25 kg as an assembly
                    </td>
                  </tr>
                  <tr>
                    <td>Impact Insert Diameter</td>
                    <td>
                      Provide a consistent and repeatable impact insult per area
                    </td>
                    <td></td>
                    <td>Standardized to 1.27 cm (0.50 in)</td>
                  </tr>
                  <tr>
                    <td>Anvil and Insert Surface Finish</td>
                    <td>Provide a consistent impact insult per area</td>
                    <td></td>
                    <td>
                      Standardized to 1.3 – 1.8 µm (50 – 70 µin).
                      <router-link
                        class="link"
                        to="/library/equipment-standards-procedures"
                        target="_blank"
                        >There are standardized MBOM Impact Insert and Anvil
                        Surfacing Procedures</router-link
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Material and hardness of impact insert and anvil</td>
                    <td>Provide a standardized hardness</td>
                    <td>
                      Material and hardness of common process equipment tooling
                    </td>
                    <td>
                      Standardized as MGR tool steel (A8) with a hardness of
                      Rockwell “C” 50 – 55
                    </td>
                  </tr>
                  <tr>
                    <td>Surface contact between anvil and insert</td>
                    <td>Provide a uniform impact surface</td>
                    <td>
                      A contact pattern other than a whole, full circle will
                      result in localized high and low energy points applied to
                      the sample
                    </td>
                    <td>
                      Contact pattern visually verified to be a whole, full
                      circle using NCR paper, carbon paper, or equivalent;
                      otherwise the impact insert and/or anvil are replaced
                    </td>
                  </tr>
                  <tr>
                    <td>Sample thickness</td>
                    <td>Obtain a repeatable result</td>
                    <td></td>
                    <td>
                      Powders are tested in a monolayer; solid propellants are
                      tested in thin slices 0.08 cm thick; liquids are tested in
                      a recess formed with tape 0.015 cm thick; semisolids are
                      tested as a monolayer if largest particle size is greater
                      than 0.015 cm and if the particles size is less than that
                      then the sample is tested in a recess formed with tape
                      0.015 cm thick.
                    </td>
                  </tr>
                  <tr>
                    <td>Sample quantity</td>
                    <td>
                      Provide a uniform distribution of material so that energy
                      is not imparted to a localized area
                    </td>
                    <td>
                      Insufficient quantity to cover the entire impact insert
                      will result in uncharacteristically high energies applied
                      to the sample
                    </td>
                    <td>
                      Place enough material on the anvil to cover an area in
                      excess of the 1.3 square cm (0.2 square inch) area of the
                      impact insert; solid slices to have a minimum edge length
                      of 1.6 cm (5/8 in)
                    </td>
                  </tr>
                  <tr>
                    <td>Test Temperature and Humidity</td>
                    <td>
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td></td>
                    <td>Standardized as ambient temperature and humidity</td>
                  </tr>
                  <tr>
                    <td>Characterization of reaction types</td>
                    <td>
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td></td>
                    <td>
                      Audible report or production of smoke, fire, charring or
                      visible light
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Adequately determine the relative sensitivity result with
                      the fewest number of trials
                    </td>
                    <td></td>
                    <td>At least 6 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Reaction upon insult</td>
                    <td>Detection of reaction</td>
                    <td>
                      The test result is considered “+” if at least one reaction
                      occurs (audible report or production of smoke, fire,
                      charring or visible light) in six trials
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="Impact Setup Example"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/setup-min.jpg')"
              target="_blank"
            >
              <img
                style="width:60%;"
                class="m-auto"
                src="@/assets/IPSeries/setup-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Flash and Audible Report (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129009?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129009"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of Jetting (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166226532?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166226532"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
